/* layouts/_admin-login.scss */

body.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(https://localhost/ravarubutiken/wp-content/themes/realm/assets/build/image/bgrb.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    display: block;
    overflow: auto;
  }

  #login {
    background: #f7eedf;
    padding: 2rem;
    border-radius: 1rem;
    margin: 0 auto;
  }

  #login-message {
    &.notice {
      border-left: 4px solid #a4b75f;
    }
  }

  input[type=checkbox] {
    background: #f7eedf;
    border: 0 solid #f7eedf;
    box-shadow: none;
    padding: 1px !important;
  }

  h1 a {
    background-image: url(https://localhost/ravarubutiken/wp-content/themes/realm/assets/build/image/logo.png);
    width: 100%;
    height: 5em;
    background-size: contain;
    background-position: left;
  }

  form#loginform,
  form#lostpasswordform,
  form#registerform {
    background: #fff;

    .forgetmenot {
      margin-top: 0.75rem;
    }

    label {
      font-size: 0.9rem;
      margin-bottom: 0.25rem;
      color: #000;
    }

    #login_error,
    .message {
      border-left: 4px solid #000;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      background-color: #d4e4fd !important;
      background-image: none !important;
      color: rgb(0, 0, 0) !important;
    }

    .button-primary {
      text-decoration: none;
      text-shadow: none;
      background-color: #b8cd6c;
      border-color: transparent;
      color: rgba(0, 0, 0, .7);
      border-radius: 290486px;
      font-weight: 600 !important;
      width: 100%;
      margin-top: 0.75rem;
      font-size: 1.1rem;

      &:hover {
        background-color: #a4b75f;
      }
    }
  }

  #nav a,
  #backtoblog a {
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: none;
      color: #a4b75f;
    }
  }

}